import '../scss/styles.scss';
// grab libs
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './App';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { DeviceDetection } from './modules/DeviceDetection';
// actions
import { startBootstrap } from 'store/app/App.actions';
import { getSiteData } from 'services/api';
import 'intersection-observer'; // polyfill for react-lazy-images

const store = configureStore(); // this populates store from localstorage if it exists

// listen for touch event
const handleTouchStart = () => {
  document.body.className = document.body.className.replace('no-touchevents', 'touch-device');
  window.removeEventListener('touchstart', handleTouchStart);
};
window.addEventListener('touchstart', handleTouchStart);

const doRender = (Component = App) => {
  render(
    <Provider store={store}>
      <Router basename={__WATCH__ ? '/JOLLYWISESITE-SRC/build/' : '/'}>
        <Route component={App} store={store} />
      </Router>
    </Provider>,
    document.getElementById('app')
  );
};

/**
 * kickstart the bootstrap
 */
window.DeviceDetection = new DeviceDetection();
// start the ball rolling with api site data
getSiteData()
  .then((response) => {
    if (response.result === 'SUCCESS') {
      const data = response.data;
      store.dispatch(startBootstrap(data));
    } else {
      return { result: 'FAILURE', reason: 'was not succesful' };
    }
  })
  .catch((error) => {
    return { result: 'FAILURE', reason: error };
  });

export const startApp = () => {
  /**
   * Forcing a promise polyfill : for IE11
   * We know this is going to be an issue for Bitmovin in Firefox
   */
  // const x = new Promise((resolve, reject) => {});
  doRender(App);

  if (process.env.NODE_ENV !== 'production') {
    module.hot.accept('./App', () => {
      const NextRootContainer = require('./App').default;
      doRender(NextRootContainer);
    });
  }

  // Trigger Service Worker
  if (__SERVICE_WORKER__) {
    const isHTTPS = window.location.protocol === 'https:';
    if ('serviceWorker' in navigator && isHTTPS) {
      navigator.serviceWorker
        .register('/static/frontend/sw.js', {
          scope: '/',
        })
        .then((registration) => {
          // console.info('SW registered: ', registration);
        })
        .catch((registrationError) => {
          // console.info('SW registration failed: ', registrationError);
        });
    } else {
      // console.info('service worker not available.');
    }
  }

  // We need to delay the listeners
  // store.dispatch(attachOneTrustListeners({ debug: false }));
};
window.addEventListener('load', function () {
  startApp();
});
