const gtagAvaiable = () => {
  return window?.gtag || false;
};

export const trackPageview = ({ title, path }) => {
  if (!gtagAvaiable()) {
    console.log('gtag not avaiable');
    cookieconsent && !cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking && removeGaCookies();
    return;
  }

  gtag('event', 'page_view', {
    page_title: title,
    page_location: path,
  });
};

// GA cookies
// https://support.google.com/analytics/answer/11397207?hl=en
const removeGaCookies = () => {
  // remove _ga cookie.
  document.cookie = '_ga=; Path=/; Domain=' + location.hostname + '; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  // remove _ga_<container-id> .
  document.cookie = '_ga_XB69N41FLC=; Path=/; Domain=' + location.hostname + '; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
