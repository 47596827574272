//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import constants
import { SITE_STATUS } from 'constants/app/AppConstants';

// import containers
import Header from 'containers/sections/Sections.header';
import Router from 'containers/router/Router.main';

// impoprt selectors
import { getBootstrapComplete } from 'store/app/App.selectors';

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { bootstrapComplete } = this.props;
    if (bootstrapComplete === false) {
      // Placeholder loading
      return null;
    }

    return (
      <React.Fragment>
        <Header />
        <Router />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bootstrapComplete: getBootstrapComplete(state),
  };
};

export default connect(mapStateToProps, null)(App);
